.divParent{
    width: calc(40vw);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background: #F2F2F2;
    border-radius: 3px;
    height: 60px;
    align-items: center;
}
.divChild1{
    font-weight: 600;
}
.divChild2{
    background: white;
    padding: 7px 20px 7px 20px;
    border-radius: 5px;
}