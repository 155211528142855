@import 'styles/component';
@import 'styles/layout';
@import 'styles/mui';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

body {
  font-family: Montserrat, sans-serif;
  font-size: 1rem;

  .epayz {
    font-family: Arial, sans-serif;
  }
}

code,
pre,
input[type='code'],
textarea[type='code'] {
  font-family: 'Inconsolata', monospace !important;
}

* {
  outline: none !important;
}

a:hover {
  text-decoration: none !important;
}

:root {
  --color-primary-dark: #001833;
  --color-primary-main: #1c2b4d;
  --color-primary-light: #2e6188;

  --color-secondary-dark: #123456;
  --color-secondary-main: #123456;
  --color-secondary-light: #123456;

  --color-action-main: #bf975c;
  --color-info-main: #0fb7ad;
}

:root .epayz {
  --color-primary-dark: #971616;
  --color-primary-main: #b91c1c;
  --color-primary-light: #404040;
}

@property --progress-value {
  syntax: '<integer>';
  inherits: false;
  initial-value: 0;
}

@keyframes A-progress {
 to { --progress-value: 81; }
}

@keyframes B-progress {
  to { --progress-value: 77; }
}

@keyframes C-progress {
  to { --progress-value: 72; }
}

@keyframes D-progress {
  to { --progress-value: 54; }
}

.progress-bar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  
  /* to center the percentage value */
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-bar::before {
  font-size: 22px;
  font-weight: 500;
  counter-reset: percentage var(--progress-value);
  content: counter(percentage) '%';
}

.progress-bar.A::before {
  color: #6fd7d7;
}

.progress-bar.B::before {
  color: #28a0d4;
}

.progress-bar.C::before {
  color: #0070c0;
}

.progress-bar.D::before {
  color: #354155;
}

.A {
  background: 
    radial-gradient(closest-side, white 79%, transparent 80% 100%),
    conic-gradient(#6fd7d7 calc(var(--progress-value) * 1%), #d0cece 0);
  animation: A-progress 3s 1 forwards;
  animation-delay: 3s;
}

.A::before {
  animation: A-progress 3s 1 forwards;
  animation-delay: 3s;
}

.B {
  background: 
    radial-gradient(closest-side, white 79%, transparent 80% 100%, white 0),
    conic-gradient(#28a0d4 calc(var(--progress-value) * 1%), #d0cece 0);
  animation: B-progress 3s 1 forwards;
  animation-delay: 3s;
}

.B::before {
  animation: B-progress 3s 1 forwards;
  animation-delay: 3s;
}

.C {
  background: 
    radial-gradient(closest-side, white 79%, transparent 80% 100%, white 0),
    conic-gradient(#0070c0 calc(var(--progress-value) * 1%), #d0cece 0);
  animation: C-progress 3s 1 forwards;
  animation-delay: 3s;
}

.C::before {
  animation: C-progress 3s 1 forwards;
  animation-delay: 3s;
}

.D {
  background: 
    radial-gradient(closest-side, white 79%, transparent 80% 100%, white 0),
    conic-gradient(#354155 calc(var(--progress-value) * 1%), #d0cece 0);
  animation: D-progress 3s 1 forwards;
  animation-delay: 3s;
}

.D::before {
  animation: D-progress 3s 1 forwards;
  animation-delay: 3s;
}