.react-calendar{
    width: 100%;
    border-radius: 10px;
    border-color: #bfbfbf;
    padding-bottom: 20px;
    margin-top: 6px;
}
.react-calendar__navigation__label{
    max-width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
}
.react-calendar__navigation__prev2-button{
    display: none;
}
.react-calendar__navigation__next2-button{
    display: none;
}
.react-calendar__navigation{
    justify-content: center;
}
.react-calendar__navigation__prev-button{
    transform: rotate(180deg);
    color: #25bec3;
}
.react-calendar__navigation__next-button{
    color: #25bec3;
}
span[class="react-calendar__navigation__label__labelText react-calendar__navigation__label__labelText--from"]{
    font-weight: 700;
    font-size: 25px;
    vertical-align: middle;
    color: #0f5099;
}
.react-calendar__month-view__weekdays__weekday > abbr{
    text-decoration: none !important;
    color: #7f7f7f;
}
.react-calendar__month-view__days__day--neighboringMonth{
    color: #d1d1d1
}
.react-calendar__month-view__days__day{
    font-size: 13px;
    font-weight: 600;
}
.calendarInfoUpdate{
    font-size: 12px;font-family: arial; float: right;margin-top: 5px; font-style: italic; font-weight: 600;
}
.react-calendar-first-day-tinhcong{
    color: white !important;
    background: #25bec3 !important;
}
.react-calendar-first-day-tinhcong-child{
    color: #2460a3 !important;
    background: #dae3f3 !important;
}
.react-calendar__tile--active{
    color: #2460a3 !important;
    background: #dae3f3 !important;
}
.react-calendar__tile--rangeStart{
    color: white !important;
    background: #25bec3 !important;
}
.react-calendar__tile--rangeEnd{
    color: white !important;
    background: #25bec3 !important;
}