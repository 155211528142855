.tess{
    background: white;
    width: 50px;
    height: 50px;
}
.arrow-down {
    width: 0; 
    height: 0; 
    border-left: 12.5px solid transparent;
    border-right: 13px solid transparent;
    
    border-top: 13px solid white;
  }
.arrow-down-2 {
    width: 0; 
    height: 0; 
    border-left: 12.5px solid transparent;
    border-right: 13px solid transparent;
    
    border-top: 13px solid #3B3838;
  }
.arrow-up {
    width: 0; 
    height: 0; 
    border-left: 12.5px solid transparent;
    border-right: 13px solid transparent;
    
    border-bottom: 13px solid white;
  }
  .arrow-up-2 {
    width: 0; 
    height: 0; 
    border-left: 12.5px solid transparent;
    border-right: 13px solid transparent;
    
    border-bottom: 13px solid #3B3838;
  }