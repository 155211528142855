.slick-list {
  max-width: calc(100vw - 80px);
  margin: auto;
}

.slick-dots {
  li button::before,
  li.slick-active button::before {
    color: red;
    font-size: 16px;
  }
}

.notail {
  p {
    margin: 16px 0;
  }
  ol,
  ul {
    padding-left: 40px;
    > li {
      margin: 8px 0;
    }
  }
  ul {
    list-style: disc;
  }
  p + ol {
    list-style: decimal;
  }
  li + ol {
    list-style: lower-alpha;
  }
}
